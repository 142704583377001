// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkItem_wrapper__l7Uw8 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 5px;\n}\n\n.LinkItem_icon__QiVW\\+ {\n    width: 16px;\n    height: 16px;\n    margin-right: 4px;\n}\n\n.LinkItem_link__niDkr {\n    text-decoration: none;\n    color: black;\n}\n\n.LinkItem_link__niDkr:hover {\n    color: #2875a9;\n}", "",{"version":3,"sources":["webpack://./src/components/LinkItem/LinkItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 5px;\n}\n\n.icon {\n    width: 16px;\n    height: 16px;\n    margin-right: 4px;\n}\n\n.link {\n    text-decoration: none;\n    color: black;\n}\n\n.link:hover {\n    color: #2875a9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LinkItem_wrapper__l7Uw8",
	"icon": "LinkItem_icon__QiVW+",
	"link": "LinkItem_link__niDkr"
};
export default ___CSS_LOADER_EXPORT___;
