import React from 'react'
import style from './Content.module.css'
import Education from '../Education'
import Certificates from '../Certificates'
import Skills from '../Skills'
import Languages from '../Languages'
import Interests from '../Interests'
import { education, certificates, skills, languages, interests } from '../../../../utilis'

const Content = () => {
    return(
        <div className={style.wrapper} >
            <div className={style.mainContent} >
                {/* <WorkExperience /> */}
            </div>
            <div className={style.offContent} >
                <Education props={education} />
                <Certificates props={certificates} />
                <Skills props={skills} />
                <Languages props={languages} />
                <Interests props={interests} />
            </div>
        </div>
    )
}

export default Content