import React from "react";
import style from "./Info.module.css";
import ProfilePhoto from '../../../../utilis/CV_512.png'

export default function Info() {
  return (
    <div className={style.wrapper}>
      <div className={style.imgWrapper}>
        <img className={style.img} src={ProfilePhoto} />
      </div>
      <div>
        <h2>Dmitry Krainiak</h2>
        <p>
          I am front-end developer. Earned skills of JavaScript on the courses
          and self-learned React and Redux. Obsessed with innovative information
          technologies. Interested in Open Source and Cyber security.
        </p>
      </div>
    </div>
  );
}
