// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_wrapper__tMMQk {\n    display: flex;\n    flex-direction: column;\n}\n\n.Header_topHalf__ZSwZ9 {\n    display: flex;\n    flex-direction: row;\n    /* justify-content: space-between; */\n    border-bottom: 1px solid lightgray;\n}", "",{"version":3,"sources":["webpack://./src/screens/CV/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,oCAAoC;IACpC,kCAAkC;AACtC","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n}\n\n.topHalf {\n    display: flex;\n    flex-direction: row;\n    /* justify-content: space-between; */\n    border-bottom: 1px solid lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Header_wrapper__tMMQk",
	"topHalf": "Header_topHalf__ZSwZ9"
};
export default ___CSS_LOADER_EXPORT___;
