import React from "react";
import style from './LinkItem.module.css'

const LinkItem = ({link}) => {
  return (
    <a className={style.link} href={link.href}>
      <div className={style.wrapper}>
        <img className={style.icon} src={link.icon} />
        {link.title}
      </div>
    </a>
  );
};

export default LinkItem