// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CVLinkList_wrapper__rpcKA {\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    border-left: 1px solid lightgray;\n    padding-left: 15px;\n    margin-bottom: 15px;\n}", "",{"version":3,"sources":["webpack://./src/screens/CV/components/CVLinkList/CVLinkList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gCAAgC;IAChC,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    border-left: 1px solid lightgray;\n    padding-left: 15px;\n    margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CVLinkList_wrapper__rpcKA"
};
export default ___CSS_LOADER_EXPORT___;
