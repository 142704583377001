// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1 {\n    color: #2875a9;\n}\n\n.CV_wrapper__IKhPo {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    padding-top: 3%;\n    padding-bottom: 3%;\n    justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/screens/CV/CV.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,uBAAuB;AAC3B","sourcesContent":["h1 {\n    color: #2875a9;\n}\n\n.wrapper {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    padding-top: 3%;\n    padding-bottom: 3%;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CV_wrapper__IKhPo"
};
export default ___CSS_LOADER_EXPORT___;
