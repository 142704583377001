import React from "react";
import style from './CVLinkList.module.css'
import LinkItem from '../../../../components/LinkItem'

const CVLinkList = ({ links }) => {
  return (
    <div className={style.wrapper} >
      {links.map((link) => {
          return <LinkItem link={link} key={link.id} />
      })}
  </div>
  )
}

export default CVLinkList;
