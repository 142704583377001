// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeScreen_wrapper__\\+f1ZV {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* background-color: #e9e9e9; */\n}", "",{"version":3,"sources":["webpack://./src/screens/HomeScreen/HomeScreen.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,+BAA+B;AACnC","sourcesContent":[".wrapper {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* background-color: #e9e9e9; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HomeScreen_wrapper__+f1ZV"
};
export default ___CSS_LOADER_EXPORT___;
