import React from 'react'
import ProfilePhoto from '../../../../utilis/CV_512.png'
import style from './Description.module.css'

const Description = () => {
    return(
        <div className={style.wrapper} >
            <img src={ProfilePhoto} alt="photo" className={style.profilePhoto} />
            <div className={style.description} >
            I am front-end developer. Earned skills of JavaScript on the courses
          and self-learned React and Redux. Obsessed with innovative information
          technologies. Interested in Open Source and Cyber security.
            </div>
        </div>
    )
}

export default Description