// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Content_wrapper__Hl-ym {\n    display: flex;\n    flex-direction: row;\n    border-bottom: 1px solid lightgray;\n    padding-top: 15px;\n    padding-bottom: 15px;\n}\n\n.Content_mainContent__MDYvf {\n    width: 75%;\n}\n\n.Content_offContent__eOQy2 {\n    padding-left: 15px;\n    border-left: 1px solid lightgray;\n}", "",{"version":3,"sources":["webpack://./src/screens/CV/components/Content/Content.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kCAAkC;IAClC,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;AACpC","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: row;\n    border-bottom: 1px solid lightgray;\n    padding-top: 15px;\n    padding-bottom: 15px;\n}\n\n.mainContent {\n    width: 75%;\n}\n\n.offContent {\n    padding-left: 15px;\n    border-left: 1px solid lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Content_wrapper__Hl-ym",
	"mainContent": "Content_mainContent__MDYvf",
	"offContent": "Content_offContent__eOQy2"
};
export default ___CSS_LOADER_EXPORT___;
