import React from "react";
import ProfileCard from "./components/ProfileCard";
import style from './HomeScreen.module.css'

const HomeScreen = () => {
  return (
    <div className={style.wrapper}>
      <ProfileCard />
    </div>
  );
};

export default HomeScreen;
