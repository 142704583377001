// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Name_wrapper__Mjml5 {\n    width: 75%;\n}", "",{"version":3,"sources":["webpack://./src/screens/CV/components/Name/Name.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd","sourcesContent":[".wrapper {\n    width: 75%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Name_wrapper__Mjml5"
};
export default ___CSS_LOADER_EXPORT___;
