import gitHubIcon from '../icons/github-brands.svg'
import telegramIcon from '../icons/telegram-brands.svg'
import linkedInIcon from '../icons/linkedin-brands.svg'
import fileIcon from '../icons/file-solid.svg'
import codeIcon from '../icons/code-solid.svg'
import envelopeIcon from '../icons/envelope-solid.svg'
import globeIcon from '../icons/globe-solid.svg'
import locationIcon from '../icons/location-dot-solid.svg'
import cardIcon from '../icons/address-card-solid.svg'

export const links = [
  {id: 1, href: 'https://github.com/deemon-k', icon: gitHubIcon, title: 'GitHub'},
  {id: 2, href: 'https://t.me/deemon_k', icon: telegramIcon, title: 'Telegram'},
  {id: 3, href: 'https://www.linkedin.com/in/dmitry-krainiak-934b84199/', icon: linkedInIcon, title: 'LinkedIn'},
  {id: 4, href: 'cv', icon: fileIcon, title: 'CV'},
  {id: 5, href: 'https://github.com/deemon-k/aboutMe', icon: codeIcon, title: 'Source'}
  ]

export const cvLinks = [
  {id: 1, href: 'https://t.me/deemon_k', icon: telegramIcon, title: 'deemon_k'},
  {id: 2, href: 'mailto:deekrainiak@gmail.com', icon: envelopeIcon, title: 'deekrainiak@gmail.com'},
  {id: 3, href: 'https://deemon.space', icon: globeIcon, title: 'deemon.space'},
  {id: 4, href: '#', icon: locationIcon, title: 'Kharkiv'}
]

export const cvFooterLinks = [
  {id: 1, href: '/', icon: cardIcon, title: 'Back to business card'}
]

export const education = [
  {id: 1, degree: 'Master', subj: 'World Economics', university: 'National Aerospace University', years: '2019 - 2020'}, // MEcon
  {id: 2, degree: 'Bachelor', subj: 'International Economics', university: 'National Aerospace University', years: '2015 - 2019'} // BScEcon
]

export const certificates = []

export const skills = []

export const languages = [
  {id: 1, lang: 'Ukrainian', level: 'Native'},
  {id: 2, lang: 'Russian', level: 'Native'},
  {id: 3, lang: 'English', level: 'Upper-Intermediate'}
]

export const interests = []

export const workExp = []