// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainList_wrapper__wP8gp {\n    padding: 15px;\n    width: 55%;\n    /* background-color: #e9e9e9; */\n    border-radius: 2px;\n    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;\n    padding: 3rem!important;\n}", "",{"version":3,"sources":["webpack://./src/screens/CV/components/MainList/MainList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,+BAA+B;IAC/B,kBAAkB;IAClB,kDAAkD;IAClD,uBAAuB;AAC3B","sourcesContent":[".wrapper {\n    padding: 15px;\n    width: 55%;\n    /* background-color: #e9e9e9; */\n    border-radius: 2px;\n    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;\n    padding: 3rem!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MainList_wrapper__wP8gp"
};
export default ___CSS_LOADER_EXPORT___;
