import React from 'react'
import style from './MainList.module.css'
import Header from '../Header'
import Content from '../Content'
import Footer from '../Footer'

const MainList = () => {
    return(
        <div className={style.wrapper} >
            <Header />
            <Content />
            <Footer />
        </div>
    )
}

export default MainList