// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkList_wrapper__tH932 {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/screens/HomeScreen/components/LinkList/LinkList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LinkList_wrapper__tH932"
};
export default ___CSS_LOADER_EXPORT___;
