import React from "react";
import LinkItem from "../../../../components/LinkItem";
import style from './LinkList.module.css'

const LinkList = ({links}) => {
  return (
    <div className={style.wrapper}>
      {links.map((link) => {
        return <LinkItem link={link} />
      })}
    </div>
  );
};

export default LinkList