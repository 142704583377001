import React from 'react'

const Education = ({props}) => {
    return(
        <div>
            <h2>EDUCATION</h2>
            {props.map((item) => {
                return(
                <div key={item.id}>
                    <b>{item.degree} in {item.subj}</b>
                    <p>{item.university}</p>
                    <p>{item.years}</p>
                </div> 
            )})}
        </div>
    )
}

export default Education