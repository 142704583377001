import React from 'react'
import Info from '../Info'
import LinkList from '../LinkList'
import style from './ProfileCard.module.css'
import {links} from '../../../../utilis'

const ProfileCard = () => {
    return(
        <div className={style.cardWrapper}>
            <Info />
            <LinkList links={links} />
        </div>
    )
}

export default ProfileCard