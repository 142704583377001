import React from 'react'
import Name from '../Name'
import CVLinkList from '../CVLinkList'
import Description from '../Description'
import { cvLinks } from '../../../../utilis'
import style from './Header.module.css'


const Header = () => {
  return (
    <div className={style.wrapper} >
      <div className={style.topHalf} >
        <Name />
        <CVLinkList links={cvLinks} />
      </div>
        <Description />
    </div>
  );
};

export default Header;
