// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Description_wrapper__qEvdO {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding-top: 15px;\n    padding-bottom: 15px;\n    /* padding: 15px inherit; */\n    border-bottom: 1px solid lightgray;\n}\n\n.Description_profilePhoto__Wmg1r {\n    width: 128px;\n    height: 128px;\n    margin-right: 30px;\n}", "",{"version":3,"sources":["webpack://./src/screens/CV/components/Description/Description.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB,2BAA2B;IAC3B,kCAAkC;AACtC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding-top: 15px;\n    padding-bottom: 15px;\n    /* padding: 15px inherit; */\n    border-bottom: 1px solid lightgray;\n}\n\n.profilePhoto {\n    width: 128px;\n    height: 128px;\n    margin-right: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Description_wrapper__qEvdO",
	"profilePhoto": "Description_profilePhoto__Wmg1r"
};
export default ___CSS_LOADER_EXPORT___;
