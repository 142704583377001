import React from 'react'

const Languages = ({props}) => {
    return(
        <div>
            <h2>LANGUAGES</h2>
            {props.map((item) => {
                return <p key={item.id}>{item.lang} ({item.level})</p>
            })}
        </div>
    )
}

export default Languages