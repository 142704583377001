import React from 'react'
import style from './Name.module.css'

const Name = () => {
    return(
        <div className={style.wrapper} >
            <h1>DMITRY KRAINIAK</h1>
            <h3>Front-end Developer</h3>
        </div>
    )
}

export default Name