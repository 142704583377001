import React from 'react'
import MainList from './components/MainList'
import style from './CV.module.css'

export default function CV() {
    return (
        <div className={style.wrapper} >
            <MainList />
        </div>
    )
}