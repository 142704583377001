import React from 'react'
import { cvFooterLinks } from '../../../../utilis'
import LinkItem from '../../../../components/LinkItem'

const Footer = () => {
    return (
        <div>
            {cvFooterLinks.map((link) => {
                return <LinkItem link={link} />
            })}
        </div>
    )
}

export default Footer